import * as React from "react"
import { graphql, HeadProps as GatsbyHeadProps } from "gatsby"
import * as striptags from "striptags"
import config from "../config"
import interBoldWoff2 from "../assets/fonts/inter/inter-v8-latin-700.woff2"

// allow non-primary domains to disable indexing site-wide e.g. v3.gatsbyjs.com
const siteNoindex = process.env.GATSBY_NOINDEX === "true"

const SCHEMALESS_RE = /^\/\//
const addURLSchema = str => (SCHEMALESS_RE.test(str) ? "https:" + str : str)

export function Head({
  location,
  params,
  data,
  pageContext,
  title,
  description,
  image,
  noindex = false,
  children,
}: HeadProps) {
  const url = config.url + location.pathname
  const pageTitle = !!title ? `${title} | ${config.title}` : config.title
  const pageDescription = !!description ? description : config.description
  const ogImage = image ? addURLSchema(image) : config.image

  return (
    <>
      <link
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        type="font/woff2"
        href={interBoldWoff2}
      />
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="image" content={ogImage} />
      <meta name="keywords" content={config.keywords} />

      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={config.title} />
      <meta property="og:url" content={url} />

      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={ogImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={config.twitter} />
      <meta name="twitter:creator" content={config.twitter} />

      <meta name="fb:app_id" content={config.fbAppID} />
      {(siteNoindex || noindex) && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {children}
    </>
  )
}

// handles Contentful-specific component logic
export function ContentfulHead(props: ContentfulHeadProps) {
  if (!props.data.contentfulPage && !props.data.contentfulLandingPage) {
    // fallback for if this is misused
    return <Head {...props} />
  }

  const page = props.data.contentfulPage || props.data.contentfulLandingPage
  const description = page.title ? striptags(page.title) : config.title

  return (
    <Head
      {...props}
      title={page.name}
      description={description}
      image={page.socialMediaImage?.file?.url}
    />
  )
}

type DataProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

interface HeadProps extends GatsbyHeadProps<DataProps> {
  title?: string
  description?: string
  image?: string
  noindex?: boolean
  children?: any
}

interface ContentfulPage {
  title?: string
  name?: string
  socialMediaImage?: {
    file?: {
      url?: string
    }
  }
}

type ContentfulDataProps = {
  contentfulPage?: ContentfulPage
  contentfulLandingPage?: ContentfulPage
}

interface ContentfulHeadProps extends GatsbyHeadProps<ContentfulDataProps> {
  title?: string
  description?: string
  image?: string
  noindex?: boolean
  children?: any
}

export const fragments = graphql`
  # Used in:
  # - templates/page
  # - templates/guides-list
  # - templates/how-it-works
  # - templates/guide-default
  # - templates/gatsby-days
  # - templates/gatsby-days/details
  # - templates/guide-react
  fragment SocialMediaImage on ContentfulPage {
    socialMediaImage {
      file {
        url
      }
    }
  }
`

export default Head
