/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { MdTranslate as TranslateIcon } from "react-icons/md"

import { langs, defaultLang } from "../../utils/i18n"
import Layout from "../../layout"
import SiteHead from "../../head"
import Container from "../../components/container"
import LanguageThumb from "../../components/languages/language-thumb"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

const TranslateBackground = styled(TranslateIcon)`
  position: absolute;
  right: calc(100% + 2rem);

  font-size: 10rem;
  opacity: 0.25;

  color: ${p => p.theme.colors.grey[90]};
`

const LanguagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${p => p.theme.space[5]};
  margin-bottom: ${p => p.theme.space[9]};
`

const allLanguages = [
  { code: `en`, name: `English`, localName: `English` },
  ...langs,
]

const LanguagesHeader = () => (
  <section>
    <h1 id="introduction" css={{ mt: 0 }}>
      Languages
    </h1>
    <p>
      The Gatsby documentation is available in the languages listed below. Click
      to set your language preference. If content is not available in your
      preferred language, we will display the English default version.
    </p>
  </section>
)

const LanguagesFooter = () => (
  <section>
    <h2>How to help with translation</h2>
    <p>
      The Gatsby documentation is currently translated in over{` `}
      {allLanguages.length}+ local languages. Most of the translations are
      contributed by our international members.
    </p>
    <p>
      {`Don't see your language above? `}
      <Link to="/contributing/translation/">Let us know</Link>.
    </p>
  </section>
)

export function Head(props) {
  return (
    <SiteHead
      {...props}
      title="Languages"
      description={`The Gatsby documentation is currently translated in over ${allLanguages.length}+ local languages. Set your language preference.`}
    />
  )
}

export default function LanguagesPage({ location, pageContext }) {
  return (
    <Layout pathname={location.pathname}>
      <Container css={{ maxWidth: 640 }}>
        <TranslateBackground />
        <main>
          <SkipNavTarget />
          <LanguagesHeader />
          <LanguagesContainer>
            {allLanguages.map(lang => (
              <LanguageThumb
                key={lang.code}
                lang={lang}
                isCurrent={lang.code === (pageContext.locale || defaultLang)}
              />
            ))}
          </LanguagesContainer>
          <LanguagesFooter />
        </main>
      </Container>
    </Layout>
  )
}
